import React from 'react';
import { createRoot } from 'react-dom/client';

import './styles/main.scss';

import Routes from './Routes';

const render = (domNode) => {
  console.log('Mount NUI application');

  const root = createRoot(domNode);
  root.render(<Routes />);

  return () => {
    console.log('Unmount NUI application');

    root.unmount();
  };
};

window.renderNUI = render;
