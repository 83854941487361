import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import fetchUser from './actions/fetch';
import updateUser from './actions/update';
import userLogout from './actions/logout';

const useUser = create(immer((set, get) => ({
  user: null,
  isFetching: true,
  actions: {
    fetch: async (opts) => await fetchUser({ set, get, opts }),
    update: async (opts) => await updateUser({ set, get, opts }),
    logout: async (opts) => await userLogout({ set, get, opts }),
  },
})));

export default useUser;
