import React, { useEffect, lazy, Suspense } from 'react';
import { Switch, Route } from "wouter";
import { Toaster } from "react-hot-toast";

const Searches = lazy(() => import('./components/routes/searches/Main'));
const Components = lazy(() => import('./components/routes/components/Main'));

import useUser from './stores/user/index';

const Routes = () => {
  const { isFetching, actions } = useUser();
  const { fetch } = actions;

  useEffect(() => {
    fetch();
  }, []);

  if (isFetching) return null;

  return (
    <>
      <Switch>
        <Route path="/searches">
          <Suspense fallback={<span />}>
            <Searches />
          </Suspense>
        </Route>
        <Route path="/components">
          <Suspense fallback={<span />}>
            <Components />
          </Suspense>
        </Route>
      </Switch>
      <Toaster />
    </>
  );
};

export default Routes;
