import fetch from 'fetch';

const fetchUser  = async ({
  set,
}) => {
  const response = await fetch('/api/users');
  const user = await response.json();

  set((state) => {
    state.user = user;
    state.isFetching = false;
  });
};

export default fetchUser;
